import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { Resolver } from 'apollo-client'

import { Configuration } from '../../../types'
import { clientConfig as clientConfigFactory } from '../utils'

import { CaptchaConfiguration } from './Query/CaptchaConfiguration'
// Critical and cannot be dynamically loaded
import { Features } from './Query/Features'
import { ResolverProvider } from './types'

const stripHTML = (value: string): string => {
  return value.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
}

export const Resolvers = (
  client: ApolloClient<NormalizedCacheObject>,
  config: Configuration['publicRuntimeConfig'],
  enableOverrides: boolean = false,
  enableSitePropertyOverrides: boolean = false,
  enableVary = false,
  productBlockContentKeys = [''],
) => {
  const clientConfig = clientConfigFactory(
    config,
    enableOverrides,
    enableSitePropertyOverrides,
    enableVary,
    productBlockContentKeys,
  )

  const withResolver = (
    resolverLoader: () => Promise<ResolverProvider>,
  ): Resolver => {
    return async (rootValue?: any, args?: any, context?: any, info?: any) => {
      const resolver = await resolverLoader()
      return resolver(client, clientConfig, config)(
        rootValue,
        args,
        context,
        info,
      )
    }
  }

  return {
    Query: {
      orderProductBlock: withResolver(
        async () =>
          (await import('./Query/Account/OrderProductBlock')).OrderProductBlock,
      ),
      attachmentUploaderConfig: withResolver(
        async () =>
          (await import('./Query/AttachmentUploaderConfig'))
            .AttachmentUploaderConfig,
      ),
      emailField: withResolver(
        async () => (await import('./Query/EmailField')).EmailField,
      ),
      passwordField: withResolver(
        async () => (await import('./Query/PasswordField')).PasswordField,
      ),
      creditAccounts: withResolver(
        async () => (await import('./Query/CreditAccounts')).CreditAccounts,
      ),
      taxInvoices: withResolver(
        async () => (await import('./Query/Invoices')).Invoices,
      ),
      accountMarketingPreferences: withResolver(
        async () =>
          (await import('./Query/AccountMarketingPreferences'))
            .AccountMarketingPreferences,
      ),
      accountDetails: withResolver(
        async () =>
          (await import('./Query/Account/AccountDetails')).AccountDetails,
      ),
      addresses: withResolver(
        async () =>
          (await import('./Query/Account/AccountAddresses')).AccountAddresses,
      ),
      paymentMethods: withResolver(
        async () => (await import('./Query/PaymentMethods')).PaymentMethods,
      ),
      siteProperties: withResolver(
        async () => (await import('./Query/SiteProperties')).SiteProperties,
      ),
      socialLoginProviders: withResolver(
        async () =>
          (await import('./Query/SocialLoginProviders')).SocialLoginProviders,
      ),
      socialAccounts: withResolver(
        async () => (await import('./Query/SocialAccounts')).SocialAccounts,
      ),
      features: Features(client, clientConfig, config),
      passwordResetTokenValidity: withResolver(
        async () =>
          (await import('./Query/PasswordResetTokenValidity'))
            .PasswordResetTokenValidity,
      ),
      form: withResolver(async () => (await import('./Query/Form')).Form),
      exchangeSocialAuthenticationToken: withResolver(
        async () =>
          (await import('./Query/ExchangeSocialAuthenticationToken'))
            .ExchangeSocialAuthenticationToken,
      ),
      captchaConfiguration: CaptchaConfiguration(client, clientConfig),
      customer: withResolver(
        async () => (await import('./Query/Customer')).Customer,
      ),
      accountCreationMarketingPreferences: withResolver(
        async () =>
          (await import('./Query/AccountCreationMarketingPreferences'))
            .AccountCreationMarketingPreferences,
      ),
      marketingPreferences: withResolver(
        async () =>
          (await import('./Query/MarketingPreferences')).MarketingPreferences,
      ),
      header: withResolver(async () => (await import('./Query/Header')).Header),
      footer: withResolver(async () => (await import('./Query/Footer')).Footer),
      basket: withResolver(
        async () => (await import('./Query/Basket/Basket')).Basket,
      ),
      miniBasket: withResolver(
        async () => (await import('./Query/Basket/MiniBasket')).MiniBasket,
      ),
      addToBasket: withResolver(
        async () => (await import('./Query/Basket/AddToBasket')).AddToBasket,
      ),
      blogHome: withResolver(
        async () => (await import('./Query/Content/HomePage')).HomePage,
      ),
      searchSeo: withResolver(
        async () => (await import('./Query/Content/SearchSeo')).SearchSeo,
      ),
      menus: withResolver(
        async () => (await import('./Query/Content/Menus')).Menus,
      ),
      instantSearch: withResolver(
        async () => (await import('./Query/InstantSearch')).InstantSearch,
      ),
      post: withResolver(
        async () => (await import('./Query/Content/Post')).Post,
      ),
      brands: withResolver(async () => (await import('./Query/Brands')).Brands),
      productAvailability: withResolver(
        async () =>
          (await import('./Query/ProductAvailability')).ProductAvailability,
      ),
      productVariant: withResolver(
        async () => (await import('./Query/ProductVariant')).ProductVariant,
      ),
      product: withResolver(
        async () => (await import('./Query/Product')).Product,
      ),
      seo: withResolver(async () => (await import('./Query/Content/Seo')).Seo),
      mainProfile: withResolver(
        async () => (await import('./Query/MainProfile')).MainProfile,
      ),
      contentSlug: withResolver(
        async () => (await import('./Query/Content/ContentSlug')).ContentSlug,
      ),
      addedToBasketModal: withResolver(
        async () =>
          (await import('./Query/Basket/AddedToBasketModal'))
            .AddedToBasketModal,
      ),
      addReviewForm: withResolver(
        async () =>
          (await import('./Query/Review/AddReviewForm')).AddReviewForm,
      ),
      loyaltyPoints: withResolver(
        async () =>
          (await import('./Query/Product/LoyaltyPoints')).LoyaltyPoints,
      ),
      quickBuyModal: withResolver(
        async () =>
          (await import('./Query/Basket/QuickBuyModal')).QuickBuyModal,
      ),
      productSummaryDetails: withResolver(
        async () =>
          (await import('./Query/Product/ProductSummaryDetails'))
            .ProductSummaryDetails,
      ),
      productSummaryPrice: withResolver(
        async () =>
          (await import('./Query/Product/ProductSummaryPrice'))
            .ProductSummaryPrice,
      ),
      productSizeGuide: withResolver(
        async () => (await import('./Query/Product/SizeGuide')).SizeGuide,
      ),
      productPrice: withResolver(
        async () => (await import('./Query/Product/ProductPrice')).ProductPrice,
      ),
      productOptions: withResolver(
        async () =>
          (await import('./Query/Product/ProductOptions')).ProductOptions,
      ),
      productOverview: withResolver(
        async () =>
          (await import('./Query/Product/ProductOverview')).ProductOverview,
      ),
      productDefaultAndVariants: withResolver(
        async () =>
          (await import('./Query/Product/ProductDefaultAndVariants'))
            .ProductDefaultAndVariants,
      ),
      productReviewContent: withResolver(
        async () =>
          (await import('./Query/Product/ProductReviewContent'))
            .ProductReviewContent,
      ),
      productRecommendations: withResolver(
        async () =>
          (await import('./Query/Product/ProductRecommendations'))
            .ProductRecommendations,
      ),
      productImages: withResolver(
        async () =>
          (await import('./Query/Product/ProductImages')).ProductImages,
      ),
      subscriptionProductBlock: withResolver(
        async () =>
          (
            await import(
              './Query/Customer/Subscription/SubscriptionProductBlock'
            )
          ).SubscriptionProductBlock,
      ),
      subscriptionEdit: withResolver(
        async () =>
          (await import('./Query/Customer/Subscription/SubscriptionEdit'))
            .SubscriptionEdit,
      ),
      sectionPeekProducts: withResolver(
        async () =>
          (await import('./Query/Widget/SectionPeekProducts'))
            .SectionPeekProducts,
      ),
      sponsoredAdsPLP: withResolver(
        async () =>
          (await import('./Query/SponsoredAds/SponsoredAdsPLP'))
            .SponsoredAdsPLP,
      ),
      flagshipWidget: withResolver(
        async () =>
          (await import('./Query/Widget/FlagshipWidget')).FlagshipWidget,
      ),
      personalisedProductRecommendations: withResolver(
        async () =>
          (await import('./Query/Widget/PersonalisedRecommendations'))
            .PersonalisedRecommendations,
      ),
      sponsoredAdsPDP: withResolver(
        async () =>
          (await import('./Query/SponsoredAds/SponsoredAdsPDP'))
            .SponsoredAdsPDP,
      ),
      productsShowcaseList: withResolver(
        async () =>
          (await import('./Query/Widget/ProductsShowcaseList'))
            .ProductsShowcaseList,
      ),
      productBlockList: withResolver(
        async () =>
          (await import('./Query/Content/ProductBlockList')).ProductBlockList,
      ),
      qubitSectionPeekProducts: withResolver(
        async () =>
          (await import('./Query/Widget/QubitSectionPeekProducts'))
            .QubitSectionPeekProducts,
      ),
      messageProductBlock: withResolver(
        async () =>
          (await import('./Query/Account/MessageProductBlock'))
            .MessageProductBlock,
      ),
      productInWishlist: withResolver(
        async () =>
          (await import('./Query/Product/ProductInWishlist')).ProductInWishlist,
      ),
      productShoeSizeMe: withResolver(
        async () =>
          (await import('./Query/Product/ProductShoeSizeMe')).ProductShoeSizeMe,
      ),
      page: withResolver(async () => (await import('./Query/Page/Page')).Page),
      recentPosts: withResolver(
        async () => (await import('./Query/Content/RecentPosts')).RecentPosts,
      ),
      contentSearch: withResolver(
        async () =>
          (await import('./Query/Content/ContentSearch')).ContentSearch,
      ),
      productContent: withResolver(
        async () =>
          (await import('./Query/Product/ProductContent')).ProductContent,
      ),
      accountSocialLinks: withResolver(
        async () =>
          (await import('./Query/Account/AccountSocialLinks'))
            .AccountSocialLinks,
      ),
      accountDiscussions: withResolver(
        async () =>
          (await import('./Query/Account/AccountDiscussions'))
            .AccountDiscussions,
      ),
      wishlist: withResolver(
        async () => (await import('./Query/Account/Wishlist')).Wishlist,
      ),
      accountProfiles: withResolver(
        async () =>
          (await import('./Query/Account/AccountProfiles')).AccountProfiles,
      ),
      componentWidgets: withResolver(
        async () => (await import('./Query/ComponentWidgets')).ComponentWidgets,
      ),
      productTags: withResolver(
        async () => (await import('./Query/Product/ProductTags')).ProductTags,
      ),
      customerPaymentCards: withResolver(
        async () =>
          (await import('./Query/Account/CustomerPaymentCards'))
            .CustomerPaymentCards,
      ),
      customerRewardOffers: withResolver(
        async () =>
          (await import('./Query/Account/CustomerRewardOffers'))
            .CustomerRewardOffers,
      ),
      wishlistCount: withResolver(
        async () =>
          (await import('./Query/Customer/Wishlist/WishlistCount'))
            .WishlistCount,
      ),
      sponsoredAdsSearch: withResolver(
        async () =>
          (await import('./Query/SponsoredAds/SponsoredAdsSearch'))
            .SponsoredAdsSearch,
      ),
      productbnplVariant: withResolver(
        async () =>
          (await import('./Query/Product/BuyNowPayLaterPaymentOptions'))
            .BuyNowPayLaterPaymentOptions,
      ),
      frequentlyBoughtTogether: withResolver(
        async () =>
          (await import('./Query/Product/FrequentlyBoughtTogether'))
            .FrequentlyBoughtTogether,
      ),
      isReturnableBlock: withResolver(
        async () =>
          (await import('./Query/Account/IsReturnableBlock')).IsReturnableBlock,
      ),
      returnUrl: withResolver(
        async () => (await import('./Query/Account/ReturnUrl')).ReturnUrl,
      ),
      referrals: withResolver(
        async () =>
          (await import('./Query/Customer/Referrals/Referrals')).Referrals,
      ),
      productBlock: withResolver(
        async () => (await import('./Query/ProductBlock')).ProductBlock,
      ),
      qubitPlacements: withResolver(
        async () =>
          (await import('./Query/Qubit/QubitPlacements')).QubitPlacements,
      ),
      customerReturnLabel: withResolver(
        async () =>
          (await import('./Query/Account/CustomerReturnLabel'))
            .CustomerReturnLabel,
      ),
      productRecommendationsProductBlockList: withResolver(
        async () =>
          (await import('./Query/ProductRecommendationsProductBlockList'))
            .ProductRecommendationsProductBlockList,
      ),
      basketRecommendationsProductBlockList: withResolver(
        async () =>
          (await import('./Query/BasketRecommendationsProductBlockList'))
            .BasketRecommendationsProductBlockList,
      ),
      sectionPeekWidgetProductBlockList: withResolver(
        async () =>
          (await import('./Query/SectionPeekWidgetProductBlockList'))
            .SectionPeekWidgetProductBlockList,
      ),
      qubitHomePageRecsWidgetProductBlockList: withResolver(
        async () =>
          (await import('./Query/QubitHomePageRecsWidgetProductBlockList'))
            .QubitHomePageRecsWidgetProductBlockList,
      ),
      clickAndCollectStores: withResolver(
        async () =>
          (await import('./Query/Basket/ClickAndCollectStores'))
            .ClickAndCollectStores,
      ),
      searchLocations: withResolver(
        async () =>
          (await import('./Query/Basket/SearchLocations')).SearchLocations,
      ),
      nearbyStores: withResolver(
        async () => (await import('./Query/Content/NearbyStores')).NearbyStores,
      ),
      storeByStoreUrlTag: withResolver(
        async () =>
          (await import('./Query/Content/StoreByStoreUrlTag'))
            .StoreByStoreUrlTag,
      ),
      stores: withResolver(
        async () => (await import('./Query/Content/Stores')).Stores,
      ),
    },
    Mutation: {
      login: withResolver(async () => (await import('./Mutation/Login')).Login),
      register: withResolver(
        async () => (await import('./Mutation/Register')).Register,
      ),
      requestSocialLinkVerificationEmail: withResolver(
        async () =>
          (await import('./Mutation/RequestSocialLinkVerificationEmail'))
            .RequestSocialLinkVerificationEmail,
      ),
      signUpForMarketing: withResolver(
        async () =>
          (await import('./Mutation/SignUpForMarketing')).SignUpForMarketing,
      ),
      unsubscribeSmsMarketing: withResolver(
        async () =>
          (await import('./Mutation/UnsubscribeSmsmarketing'))
            .UnsubscribeSmsMarketing,
      ),
      accountAddAddress: withResolver(
        async () =>
          (await import('./Mutation/AccountAddAddress')).AccountAddAddress,
      ),
      accountDeleteAddress: withResolver(
        async () => (await import('./Mutation/DeleteAddress')).DeleteAddress,
      ),
      accountReplaceAddress: withResolver(
        async () =>
          (await import('./Mutation/AccountReplaceAddress'))
            .AccountReplaceAddress,
      ),
      setDefaultBillingAddress: withResolver(
        async () =>
          (await import('./Mutation/SetDefaultBillingAddress'))
            .SetDefaultBillingAddress,
      ),
      setDefaultDeliveryAddress: withResolver(
        async () =>
          (await import('./Mutation/SetDefaultDeliveryAddress'))
            .SetDefaultDeliveryAddress,
      ),
      deletePaymentCard: withResolver(
        async () =>
          (await import('./Mutation/DeletePaymentCard')).DeletePaymentCard,
      ),
      loginAndApproveSocialLink: withResolver(
        async () =>
          (await import('./Mutation/LoginAndApproveSocialLink'))
            .LoginAndApproveSocialLink,
      ),
      forgottenPassword: withResolver(
        async () =>
          (await import('./Mutation/ForgottenPassword')).ForgottenPassword,
      ),
      resetPassword: withResolver(
        async () => (await import('./Mutation/ResetPassword')).ResetPassword,
      ),
      socialLogin: withResolver(
        async () => (await import('./Mutation/SocialLogin')).SocialLogin,
      ),
      updateMarketingPreferences: withResolver(
        async () =>
          (await import('./Mutation/UpdateMarketingPreferences'))
            .UpdateMarketingPreferences,
      ),
      cancelOrderProducts: withResolver(
        async () =>
          (await import('./Mutation/CancelOrderProducts')).CancelOrderProducts,
      ),
      cancelReturn: withResolver(
        async () => (await import('./Mutation/CancelReturn')).CancelReturn,
      ),
      cancelOrderSpecialOfferGroups: withResolver(
        async () =>
          (await import('./Mutation/CancelOrderSpecialOfferGroups'))
            .CancelOrderSpecialOfferGroups,
      ),
      addProductsToBasket: withResolver(
        async () =>
          (await import('./Mutation/AddProductsToBasket')).AddProductsToBasket,
      ),
      addProductToBasketWithSubscriptionContract: withResolver(
        async () =>
          (
            await import(
              './Mutation/AddProductToBasketWithSubscriptionContract'
            )
          ).AddProductToBasketWithSubscriptionContract,
      ),
      addPersonalisedProductToBasket: withResolver(
        async () =>
          (await import('./Mutation/AddPersonalisedProductToBasket'))
            .AddPersonalisedProductToBasket,
      ),
      sendReferralEmail: withResolver(
        async () =>
          (await import('./Mutation/SendReferralEmail')).SendReferralEmail,
      ),
      addProductToWishlist: withResolver(
        async () =>
          (await import('./Mutation/AddProductToWishlist'))
            .AddProductToWishlist,
      ),
      verifyAndAddRewardCard: withResolver(
        async () =>
          (await import('./Mutation/VerifyAndAddRewardCard'))
            .VerifyAndAddRewardCard,
      ),
      generateAndAddRewardCard: withResolver(
        async () =>
          (await import('./Mutation/GenerateAndAddRewardCard'))
            .GenerateAndAddRewardCard,
      ),
      removeRewardCard: withResolver(
        async () =>
          (await import('./Mutation/RemoveRewardCard')).RemoveRewardCard,
      ),
      removeProductFromWishlist: withResolver(
        async () =>
          (await import('./Mutation/RemoveProductFromWishlist'))
            .RemoveProductFromWishlist,
      ),
      cancelSubscription: withResolver(
        async () =>
          (await import('./Mutation/CancelSubscription')).CancelSubscription,
      ),
      updateSubscriptionAutoRenewalOverrideOptInType: withResolver(
        async () =>
          (await import('./Mutation/UpdateSubscriptionAutoRenewalOptInType'))
            .UpdateSubscriptionAutoRenewalOptInType,
      ),
      submitProfile: withResolver(
        async () => (await import('./Mutation/SubmitProfile')).SubmitProfile,
      ),
      accountProfileSubmit: withResolver(
        async () =>
          (await import('./Mutation/AccountProfileSubmit'))
            .AccountProfileSubmit,
      ),
      updateSubscriptionBillingDate: withResolver(
        async () =>
          (await import('./Mutation/UpdateSubscriptionBillingDate'))
            .UpdateSubscriptionBillingDate,
      ),
      updateAccountSettings: withResolver(
        async () =>
          (await import('./Mutation/UpdateAccountSettings'))
            .UpdateAccountSettings,
      ),
      updateEmailAddress: withResolver(
        async () =>
          (await import('./Mutation/UpdateEmailAddress')).UpdateEmailAddress,
      ),
      updatePassword: withResolver(
        async () => (await import('./Mutation/UpdatePassword')).UpdatePassword,
      ),
      addReview: withResolver(
        async () => (await import('./Mutation/AddReview')).AddReview,
      ),
      voteReviewPositive: withResolver(
        async () =>
          (await import('./Mutation/VoteReviewPositive')).VoteReviewPositive,
      ),
      voteReviewNegative: withResolver(
        async () =>
          (await import('./Mutation/VoteReviewNegative')).VoteReviewNegative,
      ),
      reportReview: withResolver(
        async () => (await import('./Mutation/ReportReview')).ReportReview,
      ),
      updateProductQuantityInBasket: withResolver(
        async () =>
          (await import('./Mutation/UpdateProductQuantityInBasket'))
            .UpdateProductQuantityInBasket,
      ),
      applyCodeToBasket: withResolver(
        async () =>
          (await import('./Mutation/ApplyCodeToBasket')).ApplyCodeToBasket,
      ),
      removeCodeFromBasket: withResolver(
        async () =>
          (await import('./Mutation/RemoveCodeFromBasket'))
            .RemoveCodeFromBasket,
      ),
      removeProductFromBasket: withResolver(
        async () =>
          (await import('./Mutation/RemoveProductFromBasket'))
            .RemoveProductFromBasket,
      ),
      checkout: withResolver(
        async () => (await import('./Mutation/Checkout')).Checkout,
      ),
      accountRemoveSocialLink: withResolver(
        async () =>
          (await import('./Mutation/AccountRemoveSocialLink'))
            .AccountRemoveSocialLink,
      ),
      accountApproveSocialLink: withResolver(
        async () =>
          (await import('./Mutation/AccountApproveSocialLink'))
            .AccountApproveSocialLink,
      ),
      createDiscussion: withResolver(
        async () =>
          (await import('./Mutation/CreateDiscussion')).CreateDiscussion,
      ),
      addSelectYourSampleProductToBasket: withResolver(
        async () =>
          (await import('./Mutation/AddSelectYourSampleProductToBasket'))
            .AddSelectYourSampleProductToBasket,
      ),
      removeSelectYourSampleProductFromBasket: withResolver(
        async () =>
          (await import('./Mutation/RemoveSelectYourSampleProductFromBasket'))
            .RemoveSelectYourSampleProductFromBasket,
      ),
      unsubscribeMarketing: withResolver(
        async () =>
          (await import('./Mutation/UnsubscribeMarketing'))
            .UnsubscribeMarketing,
      ),
      replyToDiscussion: withResolver(
        async () =>
          (await import('./Mutation/ReplyToDiscussion')).ReplyToDiscussion,
      ),
      updateSubscriptionFrequency: withResolver(
        async () =>
          (await import('./Mutation/UpdateSubscriptionFrequency'))
            .UpdateSubscriptionFrequency,
      ),
      delaySubscription: withResolver(
        async () =>
          (await import('./Mutation/DelaySubscription')).DelaySubscription,
      ),
      deleteAddress: withResolver(
        async () => (await import('./Mutation/DeleteAddress')).DeleteAddress,
      ),
      updateSubscriptionAddress: withResolver(
        async () =>
          (await import('./Mutation/UpdateSubscriptionAddress'))
            .UpdateSubscriptionAddress,
      ),
      swapSubscriptionProducts: withResolver(
        async () =>
          (await import('./Mutation/SwapSubscriptionProducts'))
            .SwapSubscriptionProducts,
      ),
      removeSubscriptionProducts: withResolver(
        async () =>
          (await import('./Mutation/RemoveSubscriptionProducts'))
            .RemoveSubscriptionProducts,
      ),
      addOnSubscriptionProducts: withResolver(
        async () =>
          (await import('./Mutation/AddOnSubscriptionProducts'))
            .AddOnSubscriptionProducts,
      ),
      signUpForEmailMarketingCampaign: withResolver(
        async () =>
          (await import('./Mutation/SignUpForEmailMarketingCampaign'))
            .SignUpForEmailMarketingCampaign,
      ),
      addProductToWaitlist: withResolver(
        async () =>
          (await import('./Mutation/AddProductToWaitlist'))
            .AddProductToWaitlist,
      ),
      addProductToWaitlistByType: withResolver(
        async () =>
          (await import('./Mutation/AddProductToWaitlistByType'))
            .AddProductToWaitlistByType,
      ),
      supersizeProductInBasket: withResolver(
        async () =>
          (await import('./Mutation/SuperSizeProductInBasket'))
            .SuperSizeProductInBasket,
      ),
      changeProductSubscriptionContractInBasket: withResolver(
        async () =>
          (await import('./Mutation/ChangeProductSubscriptionContractInBasket'))
            .ChangeProductSubscriptionContractInBasket,
      ),
    },
    // The below is included to accommodate socialLoginProviders returning a 'non-trivial' return (graphql language) this allows us to handle the resolver return the data as an array in our components
    SocialLoginProvider: {
      code: (parent) => {
        return parent.code
      },
      name: (parent) => {
        return parent.name
      },
      loginUrl: (parent) => {
        return parent.loginUrl
      },
      colour: (parent) => {
        return parent.colour
      },
      iconUrl: (parent) => {
        return parent.iconUrl
      },
    },
    // The below is included to accommodate socialAccounts returning a 'non-trivial' return (graphql language) this allows us to handle the resolver return the data as an array in our components
    SocialAccounts: {
      url: (parent) => {
        return parent.url
      },
      socialNetwork: (parent) => {
        return parent.socialNetwork
      },
    },
    Header: {
      widgets: withResolver(
        async () => (await import('./Query/Widgets')).Widgets,
      ),
    },
    Customer: {
      firstName: withResolver(
        async () => (await import('./Query/FirstName')).FirstName,
      ),
      orders: withResolver(async () => (await import('./Query/Orders')).Orders),
      ordersSpotlight: withResolver(
        async () => (await import('./Query/OrdersSpotlight')).OrdersSpotlight,
      ),
      referralCount: withResolver(
        async () => (await import('./Query/ReferralCount')).ReferralCount,
      ),
      subscriptions: withResolver(
        async () => (await import('./Query/Subscriptions')).Subscriptions,
      ),
      subscriptionOrders: withResolver(
        async () =>
          (await import('./Query/SubscriptionOrders')).SubscriptionOrders,
      ),
      customerReturnInfo: withResolver(
        async () => (await import('./Query/Account/ReturnsList')).ReturnsList,
      ),
      customerReturn: withResolver(
        async () =>
          (await import('./Query/Account/IndividualCustomerReturn'))
            .IndividualCustomerReturn,
      ),
    },
    GlobalTwoItemEditorial: {
      widgetTitle: (parent) => {
        parent.widgetTitle = parent.widgetTitle
          ? stripHTML(parent.widgetTitle)
          : parent.widgetTitle
        return parent.widgetTitle
      },
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalTwoItemEditorialPicture'))
            .GlobalTwoItemEditorialPicture,
      ),
    },
    GlobalDispatchAndDateCountdownWidget: {
      targetDate: withResolver(
        async () =>
          (await import('./Types/DispatchDateAndCountdown'))
            .DispatchAndDateCountdown,
      ),
    },
    GlobalThreeItemEditorial: {
      widgetTitle: (parent) => {
        parent.widgetTitle = parent.widgetTitle
          ? stripHTML(parent.widgetTitle)
          : parent.widgetTitle
        return parent.widgetTitle
      },
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalThreeItemEditorialPicture'))
            .GlobalThreeItemEditorialPicture,
      ),
    },
    GlobalFourItemEditorial: {
      widgetTitle: (parent) => {
        parent.widgetTitle = parent.widgetTitle
          ? stripHTML(parent.widgetTitle)
          : parent.widgetTitle
        return parent.widgetTitle
      },
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalFourItemEditorialPicture'))
            .GlobalFourItemEditorialPicture,
      ),
    },
    GlobalImageCardSet: {
      title: (parent) => {
        parent.title = parent.title ? stripHTML(parent.title) : parent.title
        return parent.title
      },
    },
    GlobalThreeItemEditorialSubtitleBG: {
      title: (parent) => {
        parent.title = parent.title ? stripHTML(parent.title) : parent.title
        return parent.title
      },
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalThreeItemEditorialSubtitleBgPicture'))
            .GlobalThreeItemEditorialSubtitleBgPicture,
      ),
    },
    GlobalEditorialWithFeature: {
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalEditorialWithFeaturePicture'))
            .GlobalEditorialWithFeaturePicture,
      ),
    },
    GlobalWidgetSirius: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalWidgetSiriusPicture'))
            .GlobalWidgetSiriusPicture,
      ),
    },
    BrandsPageWidget: {
      brands: withResolver(
        async () => (await import('./Types/BrandsPageWidget')).BrandsPageWidget,
      ),
    },
    ResponsiveProductTabs: {
      responsiveProductTabsTitle: (parent) => {
        parent.responsiveProductTabsTitle = parent.responsiveProductTabsTitle
          ? stripHTML(parent.responsiveProductTabsTitle)
          : parent.responsiveProductTabsTitle
        return parent.responsiveProductTabsTitle
      },
      banners: withResolver(
        async () =>
          (await import('./Types/ResponsiveProductTabBanners'))
            .ResponsiveProductTabBanners,
      ),
    },
    GlobalTabbedWidgetSet: {
      responsiveProductTabsTitle: (parent) => {
        parent.tabbedWidgetSetSubTitle = parent.tabbedWidgetSetSubTitle
          ? stripHTML(parent.tabbedWidgetSetSubTitle)
          : parent.tabbedWidgetSetSubTitle
        return parent.tabbedWidgetSetSubTitle
      },
      banners: withResolver(
        async () =>
          (await import('./Types/GlobalTabbedWidgetSetBanners'))
            .GlobalTabbedWidgetSetBanners,
      ),
    },
    ResponsiveProductBlockCarousel: {
      productList: withResolver(
        async () =>
          (await import('./Types/ResponsiveProductBlockCarouselProductList'))
            .ResponsiveProductBlockCarouselProductList,
      ),
      responsiveSkuList: withResolver(
        async () =>
          (await import('./Types/ResponsiveProductBlockCarouselSkuList'))
            .ResponsiveProductBlockCarouselSkuList,
      ),
    },
    GlobalTwoBestSellers: {
      ProductVariantOne: withResolver(
        async () =>
          (await import('./Types/FeaturedProductsWidget'))
            .FeaturedProductsWidgetProductOneVariant,
      ),
      ProductVariantTwo: withResolver(
        async () =>
          (await import('./Types/FeaturedProductsWidget'))
            .FeaturedProductsWidgetProductTwoVariant,
      ),
    },
    shopTheLook: {
      hotSpotVariants: withResolver(
        async () =>
          (await import('./Types/ShopTheLookWidget')).ShopTheLookProductVariant,
      ),
    },
    GlobalBuyingRightNowManualProductURL: {
      skuVariantOne: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowVariant,
      ),
      skuVariantTwo: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowTwoVariant,
      ),
      skuVariantThree: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowThreeVariant,
      ),
      skuVariantFour: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowFourVariant,
      ),
    },
    GlobalBuyingRightNow: {
      skuVariantOne: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowVariant,
      ),
      skuVariantTwo: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowTwoVariant,
      ),
      skuVariantThree: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowThreeVariant,
      ),
      skuVariantFour: withResolver(
        async () =>
          (await import('./Types/GlobalBuyingRightNowWidget'))
            .GlobalBuyingRightNowFourVariant,
      ),
    },
    GlobalProductCardScroller: {
      productVariantList: withResolver(
        async () =>
          (await import('./Types/GlobalProductCardScrollerWidget'))
            .GlobalProductCardScrollerVariants,
      ),
    },
    GlobalFourBestSellers: {
      ProductVariantOne: withResolver(
        async () =>
          (await import('./Types/BestSellersWidget'))
            .BestSellersWidgetProductOneVariant,
      ),
      ProductVariantTwo: withResolver(
        async () =>
          (await import('./Types/BestSellersWidget'))
            .BestSellersWidgetProductTwoVariant,
      ),
      ProductVariantThree: withResolver(
        async () =>
          (await import('./Types/BestSellersWidget'))
            .BestSellersWidgetProductThreeVariant,
      ),
      ProductVariantFour: withResolver(
        async () =>
          (await import('./Types/BestSellersWidget'))
            .BestSellersWidgetProductFourVariant,
      ),
    },

    ResponsiveBuildYourOwnBundle: {
      responsiveBuildYourOwnBundle: withResolver(
        async () =>
          (await import('./Types/ResponsiveBuildYourOwnBundleResolver'))
            .ResponsiveBuildYourOwnBundleResolver,
      ),
    },

    GlobalGeneralImageBanner: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalGeneralImageBannerPicture'))
            .GlobalGeneralImageBannerPicture,
      ),
    },
    GlobalPrimaryBanner: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalPrimaryBannerPicture'))
            .GlobalPrimaryBannerPicture,
      ),
    },
    GlobalPrimaryBannerWithTextOverlay: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalPrimaryBannerPicture'))
            .GlobalPrimaryBannerPicture,
      ),
    },
    GlobalPrimaryBannerWithList: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalPrimaryBannerPicture'))
            .GlobalPrimaryBannerPicture,
      ),
    },
    GlobalWidgetVega: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalWidgetVegaPicture'))
            .GlobalWidgetVegaPicture,
      ),
    },
    categoryItemCard: {
      picture: withResolver(
        async () =>
          (await import('./Types/CategoryItemCardPicture'))
            .CategoryItemCardPicture,
      ),
    },
    imageSelectCard: {
      picture: withResolver(
        async () =>
          (await import('./Types/ImageSelectCardPicture'))
            .ImageSelectCardPicture,
      ),
    },
    GlobalImageCard: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalImageCardPicture'))
            .GlobalImageCardPicture,
      ),
    },
    GlobalCardScrollerCard: {
      picture: withResolver(
        async () =>
          (await import('./Types/GlobalImageCardPicture'))
            .GlobalImageCardPicture,
      ),
    },
    GlobalSixItemCategories: {
      pictures: withResolver(
        async () =>
          (await import('./Types/GlobalSixItemCategoriesPicture'))
            .GlobalSixItemCategoriesPicture,
      ),
    },
    EmailReEngagementModal: {
      picture: withResolver(
        async () =>
          (await import('./Types/EmailEngagementModalPicture'))
            .EmailEngagementModalPicture,
      ),
    },
    ShopTheRoom: {
      productList: withResolver(
        async () =>
          (await import('./Types/ShopTheRoomSKUList')).ShopTheRoomSKUList,
      ),
      picture: withResolver(
        async () =>
          (await import('./Types/ShopTheRoomPicture')).ShopTheRoomPicture,
      ),
    },
    TestWidgetN: {
      personalisedRecommendations: withResolver(
        async () =>
          (await import('./Query/Widget/PersonalisedRecommendations'))
            .PersonalisedRecommendations,
      ),
    },
    personalisedRecommendations: {
      personalisedRecommendations: withResolver(
        async () =>
          (await import('./Query/Widget/PersonalisedRecommendations'))
            .PersonalisedRecommendations,
      ),
    },
  }
}
