import * as React from 'react'
import loadable from '@loadable/component'

import { Tooltip } from '@thg-commerce/gravity-elements/Tooltip'
import { gradient } from '@thg-commerce/gravity-theme'

import {
  InnerSwatchElement,
  OuterSwatchElement,
  SwatchDisableWrapper,
  SwatchElementButton,
  SwatchElementContainer,
  SwatchStyledProps,
} from '../styles'
import { ColourChoice } from '../Swatch'
import { SwatchShape, SwatchSize } from '../types'

const SwatchDisableCircle = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/SwatchDisableCircle'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SwatchDisableSquare = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/SwatchDisableSquare'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export interface SwatchElementProps {
  choice: ColourChoice
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  selected: boolean
  shape: SwatchShape
  borderStyle?: {
    color?: string
  }
  size?: SwatchSize
  i18nText: {
    unavailableText: string
    closeButtonText: string
  }
  persistOnOneRow?: boolean
}

const choiceLabel = (
  disabled: boolean | undefined,
  title: string,
  unavailableText: string,
) => (disabled ? `${title} ${unavailableText}` : title)

export const SwatchElement = (props: SwatchElementProps) => {
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false)

  // TODO: REBUILD-5659 PLP | PDP Multicolour Swatch background gradient configurable as part of the theme
  const multiColorSwatchGradient = [
    {
      direction: '45deg',
      stops: [
        { color: '#94BCFF', stop: 0 },
        { color: '#aac9fb00', stop: 41.99 },
      ],
    },
    {
      direction: '315deg',
      stops: [
        { color: '#77F4C7', stop: 0 },
        { color: '#f8cf6e00', stop: 53.65 },
      ],
    },
    {
      direction: '225deg',
      stops: [
        { color: '#F8CF6E', stop: 0 },
        { color: '#f8cf6e00', stop: 53.12 },
      ],
    },
    {
      direction: '135deg',
      stops: [
        { color: '#EE8888', stop: 0 },
        { color: '#f8cf6e00', stop: 53.65 },
      ],
    },
  ]

  const { size } = props
  const swatchIsSmall = React.useCallback(
    (a, b) => (size === SwatchSize.Small ? a : b),
    [size],
  )

  const swatchStyledProps: SwatchStyledProps = {
    background:
      props.choice.colour === '#MULTI'
        ? multiColorSwatchGradient
            .map((grad) =>
              gradient(
                grad.direction,
                grad.stops.map((stop) => {
                  return {
                    color: stop.color,
                    stop: stop.stop,
                  }
                }),
              ),
            )
            .join(',')
        : props.choice.colour,
    selected: props.selected,
    swatchShape: props.shape,
    swatchSize: props.size || SwatchSize.Large,
  }
  const swatchElement = (
    <SwatchElementButton
      type={props.onClick && 'button'}
      as={props.choice.href ? 'a' : 'button'}
      href={props.choice.href}
      onClick={props.onClick}
      {...(props.choice.disabled && { 'data-option-unavailable': true })}
      aria-label={choiceLabel(
        props.choice.disabled,
        props.choice.title,
        props.i18nText.unavailableText,
      )}
      title={choiceLabel(
        props.choice.disabled,
        props.choice.title,
        props.i18nText.unavailableText,
      )}
      data-testid={`${props.choice.title}-swatch`}
      {...(props.choice.disabled &&
        !props.choice.href && { 'aria-expanded': tooltipOpen })}
      {...swatchStyledProps}
    >
      <OuterSwatchElement
        disabled={props.choice.disabled}
        {...swatchStyledProps}
      >
        {props.choice.disabled && (
          <SwatchDisableWrapper borderWidth={swatchIsSmall('2px', '4px')}>
            {props.shape === SwatchShape.CIRCLE ? (
              <SwatchDisableCircle />
            ) : (
              <SwatchDisableSquare />
            )}
          </SwatchDisableWrapper>
        )}

        <InnerSwatchElement
          borderWidth={swatchIsSmall('2px', '4px')}
          elementSize={swatchIsSmall('24px', '38px')}
          disabled={props.choice.disabled}
          {...swatchStyledProps}
          borderColor={props.borderStyle?.color}
        />
      </OuterSwatchElement>
    </SwatchElementButton>
  )

  return (
    <SwatchElementContainer
      swatchSize={props.size || SwatchSize.Large}
      persistOnOneRow={props.persistOnOneRow}
    >
      {props.choice.disabled && !props.choice.href ? (
        <Tooltip
          onChange={(open: boolean) => setTooltipOpen(open)}
          triggerContent={<div>{swatchElement}</div>}
          content={
            <React.Fragment>
              {`${props.choice.key} - ${props.i18nText.unavailableText}`}
            </React.Fragment>
          }
          i18nCloseButtonText={props.i18nText.closeButtonText}
        />
      ) : (
        swatchElement
      )}
    </SwatchElementContainer>
  )
}
